import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import rehypeReact from 'rehype-react'

import ReLink from '../components/rehype/Link'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import Newsletter from '../components/Newsletter'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { a: ReLink },
}).Compiler

const PrivacyPolicyPage = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query PageQuery {
      markdownRemark(frontmatter: { path: { eq: "/privacy-policy" } }) {
        frontmatter {
          title
          path
        }
        htmlAst
      }
    }
  `)

  return (
    <Page className="p-policy has-fingerPrints">
      <SEO title={markdownRemark.frontmatter.title} />
      <Schema
        breadcrumbs={[
          {
            name: markdownRemark.frontmatter.title,
            uri: markdownRemark.frontmatter.path,
          },
        ]}
      />

      <section className="c-section c-section--policy">
        <div className="c-section__outer container">
          <div className="c-section__inner text-left">
            <div className="c-policy">{renderAst(markdownRemark.htmlAst)}</div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export default PrivacyPolicyPage
